import { zgyxRequest, request } from '@src/utils';
const baseUrlFix = '/cmsapi';

/**
 * 省打分-详情
 * @param {*} params
 * @returns
 */
const apiGetProjectResultByProvince = (params = {}) => {
  return zgyxRequest({
    method: 'get',
    url: baseUrlFix + '/selection/project-result/info/complete-result/province/evaluate',
    params
  });
};

/**
 * 省打分-详情-查看
 * @param {*} projectResultId
 * @returns
 */
const apiGetProjectResultLookByAll = (projectResultId) => {
  return zgyxRequest({
    method: 'get',
    url: baseUrlFix + `/selection/project-result/info/complete-result/view/${projectResultId}`
  });
};

/**
 * 打分保存
 * @param {*} data
 * @returns
 */
const apiPostSaveProvinceMark = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/selection/project-result/province/mark', data });
};

/**
 * 暂存所有
 * @param {*} data
 * @returns
 */
const apiPostTemporaryProvince = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/selection/project-result/province/mark/temporary', data });
};

/**
 * 省推荐
 * @param {*} data
 * @returns
 */
const apiPostTotalEvaluate = (data) => {
  return zgyxRequest({ method: 'post', url: baseUrlFix + '/selection/project-result/province/total-evaluate', data });
};

export {
  apiGetProjectResultByProvince,
  apiPostSaveProvinceMark,
  apiPostTemporaryProvince,
  apiPostTotalEvaluate,
  apiGetProjectResultLookByAll
};
