<template>
  <a-spin :spinning="spinning" :tip="tipText" size="large">
    <section class="assessment-scroll">
      <section class="assessment-wrap">
        <section class="content-wrap">
          <section class="left-wrap">
            <section class="scroll-wrap">
              <a-collapse v-model:activeKey="collapseActiveKey" :bordered="false">
                <template #expandIcon="{ isActive }">
                  <caret-right-outlined :style="collapseArrow" :rotate="isActive ? 90 : 0" />
                </template>
                <a-collapse-panel v-for="(realm, realmIndex) in realmsList" :key="realmIndex">
                  <template #header>
                    <section class="collapse-header">
                      {{ toChinesNum(realmIndex + 1) }}、{{ realm.name }}({{ realm.score }}分)
                    </section>
                  </template>

                  <div
                    class="metric-wrap"
                    :class="activeMetricIdToKeyBySelf == metric.metricIdToKeyBySelf ? 'metric-wrap-active' : ''"
                    v-for="(metric, metricIndex) in realm.metrics"
                    :key="metricIndex"
                    @click="changeMetric(metric)"
                  >
                    <span class="metric-name"> {{ metric.showIndex }}.{{ metric.name }}</span>
                    ({{ metric.score }}分)
                    <div class="metric-extra" v-if="userinfo.allowOperateType != 'read'">
                      <template v-if="metric.complete">
                        <div class="complete"><img :src="require('@src/assets/complete.png')" />已评</div>
                      </template>
                      <template v-else>
                        <div class="complete-not"><img :src="require('@src/assets/complete_not.png')" />未评</div>
                      </template>
                    </div>
                  </div>
                </a-collapse-panel>
              </a-collapse>
            </section>
          </section>
          <section class="middle-blank"></section>
          <section class="right-wrap">
            <div class="scroll-wrap" ref="right">
              <div class="project-name">
                {{ project.name }}（{{ project.regionType == 'community' ? '城镇社区' : '农村社区' }}）
                <div class="radio-wrap">
                  <a-radio-group
                    v-model:value="evaluateType"
                    @change="changeType"
                    class="layout-evaluate-type"
                    button-style="solid"
                  >
                    <a-radio-button :value="type.value" v-for="(type, typeIndex) in evaluateTypeList" :key="typeIndex">
                      {{ type.name }}
                    </a-radio-button>
                  </a-radio-group>
                </div>
              </div>

              <div>
                <div class="metric-view-name">{{ metricView.name }}({{ metricView.score }}分)</div>
                <div class="metric-view-title">一、指标说明</div>
                <div class="metric-view-text">{{ metricView.note }}</div>
                <div class="metric-view-title">二、赋分标准</div>
                <div
                  v-for="(markingNoteText, markingNoteTextIndex) in metricView.markingNoteTexts"
                  :key="markingNoteTextIndex"
                  class="metric-view-text"
                >
                  {{ markingNoteTextIndex + 1 }}.{{ markingNoteText }}
                </div>
                <div class="metric-view-title">
                  <span class="text">三、评价方式</span>
                  <a-button v-if="metricEvaluatesByKey && metricEvaluatesByKey.length > 0">
                    {{ evaluateTypeText }}
                  </a-button>
                </div>
                <template
                  v-for="(metricEvaluateObj, metricEvaluateIndex) in metricEvaluatesByKey"
                  :key="metricEvaluateIndex"
                >
                  <div class="evaluate-note">
                    <span v-if="metricEvaluateObj.required == 'yes'"> * </span>
                    {{ metricEvaluateIndex + 1 + '.' + metricEvaluateObj.note }}
                  </div>
                  <div class="evaluate-list">
                    <div class="evaluate-list-title" v-if="metricView.showType == 'ordinary'">
                      <div class="title">佐证材料</div>
                    </div>

                    <div class="evaluate-list-content">
                      <div
                        v-for="(tt, ttIndex) in selfEvaluateEvidencesEmptyAdd(metricEvaluateObj.selfEvaluateEvidences)"
                        :key="ttIndex"
                        class="evaluate-item"
                      >
                        <a-textarea
                          class="textarea-look"
                          v-model:value="tt.evidence"
                          :autoSize="{
                            minRows: 6,
                            maxRows: 6
                          }"
                          :disabled="true"
                        />

                        <div class="file-gourp-wrap">
                          <div class="file-wrap">
                            <div class="file-item" v-for="(file, fileIndex) in tt.fileList" :key="fileIndex">
                              <div class="file-item-flex">
                                <span class="file-name"> {{ fileIndex + 1 }}.{{ file.name }} </span>
                                <span v-if="!isImage(file)" class="file-preview" @click="goPreview(file)">
                                  在线预览
                                </span>
                                <span class="file-down" @click="handleDownload(file)">立即下载</span>
                              </div>
                              <div class="file-image" v-if="isImage(file)">
                                <a-image :width="120" :src="file.url" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </section>
        </section>
        <section class="footer-wrap">
          <div class="footer-flex">
            <div class="footer-left">
              <div class="metricView-name">{{ metricView.showIndex }}.{{ metricView.name }}</div>
              <div class="current-score-line">
                <span class="label">自评得分：</span>
                <span class="number-look">{{ metricView.selfEvaluateScore }}</span>
                <span class="mark">分</span>
              </div>
              <div class="current-score-line">
                <span class="label">自评总得分：</span>
                <span class="number-look">{{ Number(Number(selfEvaluateScoreAll).toFixed(2)) }}</span>
                <span class="mark">分</span>
              </div>
            </div>
            <div class="flex-line"><span></span></div>
            <div class="footer-middle">
              <div class="city-foot pt5">
                <div class="current-score-line">
                  <span class="label"> 市级查阅资料评分：</span>
                  <span class="number-look">{{ metricView.cityMaterialEvaluateScore }}</span>
                  <span class="mark"> 分</span>
                </div>
                <div class="current-score-line">
                  <span class="label"> 市级现场查看评分：</span>
                  <span class="number-look">{{ metricView.citySceneEvaluateScore }}</span>
                  <span class="mark"> 分</span>
                </div>
                <div class="current-score-line">
                  <span class="label"> 市级总得分：</span>
                  <span class="number-look">{{ cityEvaluateScoreAll }}</span>
                  <span class="mark"> 分</span>
                </div>
              </div>

              <div class="end-left">
                <div class="end-left-title">市级推荐意见：</div>

                <div class="textarea-look">
                  {{ cityOpinion }}
                </div>
              </div>
            </div>
            <div class="flex-line"><span></span></div>
            <div class="footer-right">
              <div v-if="userinfo.allowOperateType != 'read'">
                <div class="current-score-line">
                  <template v-if="evaluateType == 'material'">
                    <span class="label">查阅资料得分：</span>
                    <template v-if="metricEvaluatesByKey.length > 0">
                      <a-input-number
                        class="number"
                        v-model:value="metricView.provinceMaterialEvaluateScore"
                        :min="0"
                        :max="metricView.diffEvaluateTypeScoreMap.material || metricView.score"
                        @blur="calculationData"
                        v-if="pageType != 'look'"
                      />

                      <span class="number-look" v-else>{{ metricView.provinceMaterialEvaluateScore }}</span>
                    </template>
                    <template v-else>
                      <div class="number-look"></div>
                    </template>
                  </template>
                  <template v-if="evaluateType == 'scene'">
                    <span class="label">现场查看得分：</span>
                    <template v-if="metricEvaluatesByKey.length > 0">
                      <a-input-number
                        class="number"
                        v-model:value="metricView.provinceSceneEvaluateScore"
                        :min="0"
                        :max="metricView.diffEvaluateTypeScoreMap.scene || metricView.score"
                        @blur="calculationData"
                        v-if="pageType != 'look'"
                      />

                      <span class="number-look" v-else>{{ metricView.provinceSceneEvaluateScore }}</span>
                    </template>
                    <template v-else>
                      <div class="number-look"></div>
                    </template>
                  </template>

                  <span class="mark"> 分</span>
                </div>
                <div class="current-score-line">
                  <span class="label">省级评审总得分：</span>

                  <span class="number-look">{{ provinceEvaluateScoreAll }}</span>
                  <span class="mark"> 分</span>
                </div>
              </div>

              <div class="footer-button-group">
                <div class="footer-button" v-if="pageType != 'look'">
                  <a-button size="large" type="primary" @click="handleApiPostTemporary(true)" block v-preReClick>
                    保存
                  </a-button>
                </div>

                <div class="footer-button">
                  <a-button @click="preMetric" size="large" block>上一项</a-button>
                </div>
                <div class="footer-button">
                  <a-button @click="nextMetric" size="large" block>下一项</a-button>
                </div>

                <div class="footer-button" v-if="pageType != 'look'">
                  <a-popconfirm placement="top" ok-text="已保存" cancel-text="取消" @confirm="handleApiPostSave">
                    <template #title> 提交后无法修改，是否确认提交？ </template>
                    <a-button size="large" block type="primary">确认提交</a-button>
                  </a-popconfirm>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <previewFile :file="activeFile" v-if="visibleModalFile" @onCancel="onCancelModalFile"></previewFile>
      <mp3or4
        :fileUrl="activeFileUrl"
        :fileType="activeFileType"
        v-if="visibleModalMp"
        @onCancel="visibleModalMp = false"
      ></mp3or4>
    </section>
  </a-spin>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { message } from 'ant-design-vue';
import { CaretRightOutlined, CheckCircleFilled, DeleteOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import {
  apiGetProjectResultByProvince,
  apiPostSaveProvinceMark,
  apiPostTemporaryProvince,
  apiPostTotalEvaluate,
  apiGetProjectResultLookByAll
} from '@src/apis/province';
import { previewPdfApi } from '@src/apis/index';
import { downloadFile2 } from '@src/utils';

import { previewFile, mp3or4 } from '@src/components';
export default {
  data() {
    return {
      activeFileUrl: '',
      activeFileType: '',
      visibleModalMp: false,
      isEmpty,
      //
      collapseArrow: {
        color: '#C5C5C5'
      },
      //
      collapseActiveKey: [],
      project: {},
      projectResult: {},
      projectResultId: '',
      temporaryJson: '',
      activeMetricIdToKeyBySelf: '',
      minMetricIdToKeyBySelf: '',
      maxMetricIdToKeyBySelf: '',
      previewFlag: false, //预览
      selfEvaluateScoreAll: 0, //自评总得分
      requestFlag: false,
      pageType: 'edit',
      activeFile: {},
      visibleModalFile: false,
      realmsList: [], //领域数组
      tipText: '加载中......',
      spinning: false,
      //评分类型
      evaluateType: '',
      opinion: '',
      cityEvaluateScoreAll: 0, //市级总分当前模块的计算分数
      cityOpinion: '',
      provinceEvaluateScoreAll: ''
    };
  },
  components: { CaretRightOutlined, CheckCircleFilled, DeleteOutlined, LoadingOutlined, previewFile, mp3or4 },
  created() {
    const _this = this;
    _this.evaluateType = _this.evaluateTypeList[0].value;
    _this.projectResultId = _this.$route.query.id;
    _this.pageType = _this.$route.query.type || '';
    _this.$nextTick(async () => {
      await _this.getDetails();
    });
  },
  computed: {
    ...mapGetters(['userinfo', 'dictionaries', 'token']),
    evaluateTypeList() {
      return this.dictionaries.evaluateType || [];
    },
    metricView() {
      let metricObject = {};
      if (this.realmsList && this.realmsList.length > 0) {
        this.realmsList.forEach((realm, realmIndex) => {
          realm.metrics.forEach((metric, metricIndex) => {
            if (metric.metricIdToKeyBySelf == this.activeMetricIdToKeyBySelf) {
              metricObject = metric;
            }
          });
        });
      }

      return metricObject;
    },
    //评价方式
    metricEvaluatesByKey() {
      const _this = this;
      let metricEvaluatesByFilter = [];
      if (_this.metricView && _this.metricView.metricEvaluates) {
        Object.keys(_this.metricView.metricEvaluates).forEach((key) => {
          if (key == _this.evaluateType) {
            metricEvaluatesByFilter = _this.metricView.metricEvaluates[key];
          }
        });
      }

      return metricEvaluatesByFilter;
    },
    //评价方式文字版
    evaluateTypeText() {
      let text = '';
      this.evaluateTypeList.forEach((item) => {
        if (item.value == this.evaluateType) {
          text = item.name;
        }
      });
      return text;
    }
  },
  methods: {
    ...mapActions(['setActiveMenu', 'setActiveMenuReplace']),
    //切换评价方式
    changeType(e) {
      try {
        const _this = this;
        _this.evaluateType = e.target.value;
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 提交表单
     */
    async handleSaveForm() {
      try {
        const _this = this;
        const res = await apiPostTotalEvaluate({
          opinion: '',
          projectResultId: _this.projectResultId
        });
        if (res.code === '00000') {
          message.success('提交成功');

          //跳转到列表
          setTimeout(() => {
            _this.setActiveMenuReplace({ path: '/provinceExamine', name: '', query: {} });
          }, 1000);
        } else {
          // message.error(res.message ?? '提交失败');
        }
      } catch (error) {
        console.error(error);
      }
    },

    //执行检查是否完全 + 分数
    calculationData() {
      const _this = this;
      _this.chenckQuota();
      _this.calculationAllScore();
    },
    //计算每个标相加的总分数
    calculationAllScore() {
      try {
        const _this = this;
        let metricsList = [];
        _this.realmsList.forEach((item) => {
          metricsList = metricsList.concat(item.metrics);
        });
        let score = 0;
        let cityScore = 0;
        let scoreProvince = 0;
        metricsList.forEach((item) => {
          score += Number(item.selfEvaluateScore || 0);
          cityScore += Number(item.cityEvaluateScore || 0);
          scoreProvince += Number(item.provinceMaterialEvaluateScore || 0);
          scoreProvince += Number(item.provinceSceneEvaluateScore || 0);
        });
        _this.selfEvaluateScoreAll = Number(Number(score).toFixed(2));
        _this.cityEvaluateScoreAll = Number(Number(cityScore).toFixed(2));
        _this.provinceEvaluateScoreAll = Number(Number(scoreProvince).toFixed(2));
      } catch (error) {
        console.log(error);
      }
    },
    //点击计算是否完成,不管是不是只有现场查看，统一判断 分数+里面的评价方式的必填校验
    chenckQuota() {
      // 判断是否填完
      try {
        const _this = this;
        _this.realmsList.forEach((currentRealm) => {
          currentRealm.metrics.forEach((currentZb) => {
            let complete = true;

            if (currentZb.selfMaxScore != undefined && currentZb.selfMaxScore == 0) {
              if (!currentZb.provinceSceneEvaluateScore && currentZb.provinceSceneEvaluateScore !== 0) {
                //没填自评分
                complete = false; //没完整
              }
              currentZb.complete = complete;
            } else {
              if (!currentZb.provinceMaterialEvaluateScore && currentZb.provinceMaterialEvaluateScore !== 0) {
                //没填自评分
                complete = false; //没完整
              }
              currentZb.complete = complete;
            }
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    //校验和显示无关,保存前的校验，不包含只有现场查看的
    chenckQuotaComplete() {
      try {
        const _this = this;
        let list = _this.realmsList;
        let allComplete = true;
        for (let i = 0; i < list.length; i++) {
          let currentRealm = list[i];
          for (let y = 0; y < currentRealm.metrics.length; y++) {
            let currentZb = currentRealm.metrics[y];
            let complete = true;

            if (!(currentZb.selfMaxScore != undefined && currentZb.selfMaxScore == 0)) {
              if (!currentZb.provinceMaterialEvaluateScore && currentZb.provinceMaterialEvaluateScore !== 0) {
                //没填自评分
                complete = false; //没完整
                message.error(currentRealm.name + '/' + currentZb.name + '--未评分');
                return false;
              }
              if (!complete) {
                allComplete = allComplete && complete;
                return false;
              }
            }
          }
        }

        return allComplete;
      } catch (error) {
        console.log(error);
      }
    },
    //设置获取来的数据到页面
    setProjectData() {
      try {
        const _this = this;

        let { metricResults } = _this.projectResult;

        _this.realmsList.forEach((item, index) => {
          //域
          item.metrics.forEach((metrics, metricsIndex) => {
            //指标
            let metricNo = metrics.metricNo; // 指标编号

            Object.keys(metricResults).forEach((key) => {
              //结果指标对象遍历
              //计算全部市评分

              if (key == metricNo) {
                // 记录的指标和数据对应
                metrics.selfEvaluateScore = metricResults[key].selfEvaluateScore;
                metrics.cityEvaluateScore = metricResults[key].cityEvaluateScore; //市平分
                metrics.cityMaterialEvaluateScore = metricResults[key].cityMaterialEvaluateScore; //市平分
                metrics.citySceneEvaluateScore = metricResults[key].citySceneEvaluateScore; //市平分
                if (!_this.temporaryJson) {
                  metrics.provinceMaterialEvaluateScore = metricResults[key].provinceMaterialEvaluateScore; //省平分
                  metrics.provinceSceneEvaluateScore = metricResults[key].provinceSceneEvaluateScore;
                }

                let evaluationMethodObject = metrics.metricEvaluates; //原始的评价方式
                Object.keys(evaluationMethodObject).forEach((metricEvaluatesKey) => {
                  //
                  evaluationMethodObject[metricEvaluatesKey].forEach((evaluationMethodObjectItem) => {
                    //每个评价方式
                    let metricEvaluateResultsSub = metricResults[key].metricEvaluateResults; // 结果评价方式对象

                    Object.keys(metricEvaluateResultsSub).forEach((metricEvaluateResultsSubKey) => {
                      if (metricEvaluateResultsSubKey == evaluationMethodObjectItem.metricEvaluateNo) {
                        let selfEvaluateEvidences =
                          metricEvaluateResultsSub[metricEvaluateResultsSubKey].selfEvaluateEvidences;

                        selfEvaluateEvidences = selfEvaluateEvidences.map((selfEvaluateEvidencesItem) => {
                          //评价方式佐证材料 赋值
                          let fileList = selfEvaluateEvidencesItem.attachmentFiles;

                          fileList = fileList.map((file) => {
                            return {
                              uid: file.id,
                              name: file.fileName,
                              status: 'done',
                              response: 'Server Error 500',
                              url: file.url
                            };
                          });

                          return {
                            evidence: selfEvaluateEvidencesItem.evidence,
                            attachment: selfEvaluateEvidencesItem.attachment.split(','),
                            fileList: fileList
                          };
                        });

                        evaluationMethodObjectItem.selfEvaluateEvidences = selfEvaluateEvidences;
                      }
                    });
                  });
                });
              }
            });
          });
        });

        _this.calculationData();
      } catch (error) {
        console.log(error);
      }
    },
    //拼接id
    getFileIds(list) {
      let ids = [];
      list.forEach((item) => {
        if (item && item.id) {
          ids.push(item.id);
        } else if (item && item.uid) {
          ids.push(item.uid);
        }
      });
      return ids.join();
    },
    /**
     * 保存
     * @param {*}
     */
    async handleApiPostSave() {
      try {
        const _this = this;
        _this.calculationData();
        if (!_this.chenckQuotaComplete()) {
          return false;
        }
        if (_this.requestFlag) {
          return false;
        }
        _this.requestFlag = true;

        //

        let metricResults = [];
        _this.realmsList.forEach((item) => {
          metricResults = metricResults.concat(item.metrics);
        });
        let copyMetricResults = JSON.parse(JSON.stringify(metricResults));
        copyMetricResults = copyMetricResults.map((item) => {
          return {
            materialScore: item.provinceMaterialEvaluateScore,
            sceneScore: item.provinceSceneEvaluateScore,
            metricNo: item.metricNo
          };
        });
        let sendData = {
          projectResultId: _this.projectResultId,
          marks: copyMetricResults
        };
        console.log(sendData);

        setTimeout(() => {
          _this.requestFlag = false;
        }, 2000);

        const { data, code } = await apiPostSaveProvinceMark({ param: JSON.stringify(sendData) });

        _this.requestFlag = false;
        if (code === '00000') {
          _this.handleSaveForm();
        } else {
          // message.error('提交失败');
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 暂存
     * @param {*}
     */
    async handleApiPostTemporary(showToastFlag) {
      try {
        const _this = this;
        if (_this.pageType == 'look') {
          return false;
        }
        if (_this.requestFlag) {
          return false;
        }
        _this.requestFlag = true;
        setTimeout(() => {
          _this.requestFlag = false;
        }, 2000);

        let copyRealms = JSON.parse(JSON.stringify(_this.realmsList));
        copyRealms.forEach((item, index) => {
          //域
          item.metrics.forEach((metrics, metricsIndex) => {
            let evaluationMethodObject = metrics.metricEvaluates; //原始的评价方式

            Object.keys(evaluationMethodObject).forEach((metricEvaluatesKey) => {
              //
              evaluationMethodObject[metricEvaluatesKey].forEach((evaluationMethodObjectItem) => {
                let selfEvaluateEvidencesList = evaluationMethodObjectItem.selfEvaluateEvidences;
                selfEvaluateEvidencesList.forEach((selfEvaluateEvidencesItem) => {
                  selfEvaluateEvidencesItem.fileList = selfEvaluateEvidencesItem.fileList.map((fileItem) => {
                    return Object.assign(
                      { ...fileItem },
                      {
                        url: `@@${fileItem.uid}@@`
                      }
                    );
                  });
                });
              });
            });
          });
        });

        let param = {
          evaluateType: _this.evaluateType,
          list: copyRealms
        };
        const { code } = await apiPostTemporaryProvince({
          projectResultId: _this.projectResultId,
          param: JSON.stringify(param)
        });
        _this.requestFlag = false;
        if (code === '00000') {
          if (showToastFlag) {
            message.success('保存成功');
          }

          _this.calculationData();
        } else {
          // message.error('保存失败');
        }
      } catch (error) {
        console.log(error);
      }
    },

    //预览取消
    onCancelModalFile() {
      try {
        const _this = this;
        _this.visibleModalFile = false;
        _this.activeFile = {};
      } catch (error) {
        console.error(error);
      }
    },
    isImage(file) {
      const _this = this;
      let name = file.name;
      let splitList = name.split('.');
      let type = splitList[splitList.length - 1].toLowerCase();
      let imgType = ['gif', 'jpeg', 'jpg', 'png'];
      if (imgType.includes(type)) {
        // 图片，直接跳转打开
        return true;
      } else {
        return false;
      }
    },
    //预览
    async goPreview(file) {
      const _this = this;
      console.log(file);
      _this.tipText = '加载中...';
      _this.spinning = true;
      let name = file.name;
      let splitList = name.split('.');
      let type = splitList[splitList.length - 1].toLowerCase();

      let imgType = ['gif', 'jpeg', 'jpg', 'png'];
      let fileTypeList = ['docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx'];
      let videoType = ['mp3', 'mp4'];
      if (fileTypeList.includes(type)) {
        console.log('文件');
        if (_this.previewFlag) {
          _this.spinning = false;
          return false;
        }
        _this.previewFlag = true;
        const { uid } = file;
        const res = await previewPdfApi({ uuid: uid });
        if (res && res.code === '00000') {
          let fileNameList = res.data.fileName.split('.');
          let fileType = fileNameList[fileNameList.length - 1].toLowerCase();

          _this.activeFile = {
            pdfSrc: res.data.url,
            fileType: fileType
          };
          _this.visibleModalFile = true;
        }

        setTimeout(() => {
          _this.previewFlag = false;
        }, 1000);
      } else if (type == 'pdf') {
        _this.activeFile = {
          pdfSrc: file.url
        };
        _this.visibleModalFile = true;
      } else if (imgType.includes(type)) {
        // 图片，直接跳转打开
        window.open(file.url);
      } else if (videoType.includes(type)) {
        _this.activeFileUrl = file.url;
        _this.activeFileType = type;
        _this.visibleModalMp = true;
      } else {
        message.error('预览暂不支持当前格式的文件');
      }
      _this.spinning = false;
    },
    //展开所有折叠
    setCollapseActiveKey() {
      try {
        const _this = this;
        let collapseActiveKeyArray = [];
        _this.realmsList.forEach((item, index) => {
          collapseActiveKeyArray.push(index);
        });
        _this.collapseActiveKey = collapseActiveKeyArray;
      } catch (error) {
        console.log(error);
      }
    },
    /*
     *获取详情
     */
    async getDetails() {
      try {
        const _this = this;
        _this.spinning = true;

        let api = '';
        let param = '';
        if (_this.pageType == 'look') {
          param = _this.projectResultId;
          api = apiGetProjectResultLookByAll;
        } else {
          param = {
            projectResultId: _this.projectResultId
          };
          api = apiGetProjectResultByProvince;
        }

        const { code, data } = await api(param);
        _this.spinning = false;
        if (code === '00000' && data) {
          const { project, projectResult, temporaryJson } = data;
          const { realms } = project;
          let metricIdToKeyBySelf = 0; //自己定义的 选中每个标的key值
          _this.realmsList = realms.map((item) => {
            //增加默认的selfEvaluateEvidences评价列表
            item.metrics = item.metrics.map((sub) => {
              for (let key in sub.metricEvaluates) {
                for (let i = 0; i < sub.metricEvaluates[key].length; i++) {
                  sub.metricEvaluates[key][i].selfEvaluateEvidences = [];
                }
              }
              metricIdToKeyBySelf++;
              return {
                metricIdToKeyBySelf: metricIdToKeyBySelf,
                evaluateActiveKey: 0, //每个表里面的评价方式的选中key 0|| 1
                ...sub
              };
            });
            return {
              ...item
            };
          });
          _this.project = project; //干净的从接口拿的数据
          _this.projectResult = projectResult;
          _this.temporaryJson = temporaryJson;
          _this.orgName = projectResult.orgName;
          _this.cityOpinion = projectResult.cityOpinion;
          //激活默认选中
          _this.$nextTick(() => {
            _this.setCollapseActiveKey();
            _this.activeMetricIdToKeyBySelf = _this.realmsList[0].metrics[0].metricIdToKeyBySelf;
            _this.setMinAndMaxMetricIdToKeyBySelf();
          });
          if (temporaryJson) {
            console.log('获取暂存');
            let temporaryObj = JSON.parse(temporaryJson);

            let defaultRealms = JSON.parse(JSON.stringify(_this.realmsList));
            try {
              let tempRealms = temporaryObj.list;
              _this.realmsList.forEach((original, originalIndex) => {
                let tempMetrics = tempRealms[originalIndex].metrics;
                original.metrics.forEach((originalMetric, originalMetricIndex) => {
                  let tempMetric = tempMetrics[originalMetricIndex];
                  originalMetric.complete = tempMetric.complete;
                  // originalMetric.selfEvaluateScore = tempMetric.selfEvaluateScore;
                  // originalMetric.cityEvaluateScore = tempMetric.cityEvaluateScore;
                  // originalMetric.provinceEvaluateScore = tempMetric.provinceEvaluateScore;
                  originalMetric.provinceMaterialEvaluateScore = tempMetric.provinceMaterialEvaluateScore;
                  originalMetric.provinceSceneEvaluateScore = tempMetric.provinceSceneEvaluateScore;
                });
              });
              // throw new Error('error');
            } catch (error) {
              console.error('暂存数据读取异常', error);
              _this.realmsList = defaultRealms;
            }

            _this.evaluateType = temporaryObj.evaluateType;
            _this.calculationData();
          }
          _this.selfEvaluateScoreAll = projectResult.selfEvaluateScore;
          if (!isEmpty(projectResult.metricResults)) {
            // 暂存的数据
            _this.setProjectData();
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    //下载文件
    handleDownload(e) {
      this.tipText = '下载中...';
      this.spinning = true;
      downloadFile2(
        {
          url: e.url,
          fileName: e.name
        },
        () => {
          this.spinning = false;
        }
      );
    },
    //佐证材料默认增加一个
    selfEvaluateEvidencesEmptyAdd(list) {
      if (list.length <= 0 || !list) {
        list.push({
          evidence: '',
          attachment: [],
          fileList: []
        });
      }
      return list;
    },
    //设置最小和最大的 metricIdToKeyBySelf
    setMinAndMaxMetricIdToKeyBySelf() {
      try {
        const _this = this;
        _this.minMetricIdToKeyBySelf = _this.realmsList[0].metrics[0].metricIdToKeyBySelf;
        let lastRealm = _this.realmsList[_this.realmsList.length - 1];
        let lastMetric = lastRealm.metrics[lastRealm.metrics.length - 1];
        _this.maxMetricIdToKeyBySelf = lastMetric.metricIdToKeyBySelf;
      } catch (error) {
        console.log(error);
      }
    },
    //上一个指标
    preMetric() {
      if (this.activeMetricIdToKeyBySelf == this.minMetricIdToKeyBySelf) {
        return false;
      }
      this.activeMetricIdToKeyBySelf--;

      this.handleApiPostTemporary(false);

      this.$refs.right.scrollTop = 0;
    },
    //下一个指标
    nextMetric() {
      if (this.activeMetricIdToKeyBySelf == this.maxMetricIdToKeyBySelf) {
        return false;
      }
      this.activeMetricIdToKeyBySelf++;

      this.handleApiPostTemporary(false);

      this.$refs.right.scrollTop = 0;
    },
    //左侧metric点击改变激活的activeMetricIdToKeyBySelf
    changeMetric(e) {
      try {
        const _this = this;
        if (_this.activeMetricIdToKeyBySelf == e.metricIdToKeyBySelf) {
          return false;
        }
        _this.activeMetricIdToKeyBySelf = e.metricIdToKeyBySelf;

        this.handleApiPostTemporary(false);

        _this.$refs.right.scrollTop = 0;
      } catch (error) {
        console.log(error);
      }
    },
    //转中文
    toChinesNum(num) {
      let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
      let unit = ['', '十', '百', '千', '万'];
      num = parseInt(num);
      let getWan = (temp) => {
        let strArr = temp.toString().split('').reverse();
        let newNum = '';
        let newArr = [];
        strArr.forEach((item, index) => {
          newArr.unshift(item === '0' ? changeNum[item] : changeNum[item] + unit[index]);
        });
        let numArr = [];
        newArr.forEach((m, n) => {
          if (m !== '零') numArr.push(n);
        });
        if (newArr.length > 1) {
          newArr.forEach((m, n) => {
            if (newArr[newArr.length - 1] === '零') {
              if (n <= numArr[numArr.length - 1]) {
                newNum += m;
              }
            } else {
              newNum += m;
            }
          });
        } else {
          newNum = newArr[0];
        }
        return newNum;
      };
      let overWan = Math.floor(num / 10000);
      let noWan = num % 10000;
      if (noWan.toString().length < 4) {
        noWan = '0' + noWan;
      }
      return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num);
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>
<style lang="less">
.ant-collapse {
  border: none;
  background: transparent;
}
.ant-collapse-borderless > .ant-collapse-item {
  border: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.assessment-wrap .ant-upload-list {
  display: none;
}
.evaluate-item .ant-input-affix-wrapper-textarea-with-clear-btn .ant-input {
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
  background: #e5eeff;
  border-radius: 2px;
}
.radio-wrap {
  display: inline-block;
  vertical-align: top;
  padding-top: 4px;
}
.radio-wrap .ant-radio-group {
  display: inline-block;
  vertical-align: top;
}
</style>
